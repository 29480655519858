import * as Sentry from '@sentry/react';
import * as Router from 'react-router';

const initSentry = (history, routes) => {
    const integrations = [Sentry.replayIntegration()];

    routes &&
        integrations.push(
            Sentry.reactRouterV3BrowserTracingIntegration({
                history: history,
                routes: Router.createRoutes(routes),
                match: Router.match
            })
        );

    Sentry.init({
        dsn: 'https://0787fa2eec6f464a827bac73ef49bbb5@o80560.ingest.us.sentry.io/1342088',
        environment: process.env.ENV,
        integrations: integrations,

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        allowUrls: [/https?:\/\/((www)?(\.staging)?\.)?homeexchange\.[a-z]+/i]
    });
};

export default initSentry;
