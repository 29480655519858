export default class Feature {
    static AMENITIES_OUTDOOR = 1;
    static AMENITIES_PRACTICAL = 2;
    static AMENITIES_MULTIMEDIA = 3;
    static AMENITIES_VARIOUS = 4;
    static OPTS_TRANSPORTATION = 5;
    static OPTS_PERSONAL = 6;
    static RULE = 7;
    static FAVOR = 8;
    static AMENITIES_OTHER = 9; // Never use
    static AMENITIES_ANIMALS = 10;
    static AMENITIES_KIDS = 11;
    static AMENITIES_ECO = 12;
    static AMENITIES_REMOTE = 13;
    static SURROUNDING_TAGS = 14;
    static ECO_LEVEL = 4;
    static AMENITIES_TYPE_EXCHANGERS_FAVS = 'exchangers-favorites';
    static AMENITIES_TYPE_UNIQUE_FACILITIES = 'unique-facilities';
    static AMENITIES_TYPE_KIDS = 'kids-friendly';
    static AMENITIES_TYPE_REMOTE = 'remote-friendly';
    static AMENITIES_TYPE_ECO = 'eco-responsable-amenities';
    static AMENITIES_TYPE_RULE_FAVOR = 'rule-favor';
    static SURROUNDING_TYPE_TAGS = 'surrounding-tags';

    static AMENITIES_LABEL = {
        1: 'outdoor',
        2: 'practical',
        3: 'multimedia',
        4: 'various',
        5: 'transportation',
        6: 'personal',
        7: 'rule',
        8: 'favor',
        9: 'others',
        10: 'animal',
        11: 'kids',
        12: 'eco',
        13: 'remote',
        14: 'surrounding'
    };

    static AMENITIES_BIT = {
        ONE_BIT: 1,
        TWO_BIT: 2,
        FOUR_BITS: 4,
        EIGHT_BITS: 8,
        SIXTEEN_BITS: 16,
        THIRTY_TWO_BITS: 32,
        SIXTY_FOUR_BITS: 64,
        ONE_HUNDRED_TWENTY_EIGHT_BITS: 128,
        TWO_HUNDRED_FIFTY_SIX_BITS: 256,
        FIVE_HUNDRED_TWELVE_BITS: 512,
        ONE_THOUSAND_TWENTY_FOUR_BITS: 1024,
        TWO_THOUSAND_FORTY_EIGHT_BITS: 2048,
        FOUR_THOUSAND_NINETY_SIX_BITS: 4096
    };

    static AMENITIES_LABEL_DETAIL = {
        // AMENITIES_PRACTICAL
        2: {
            1: 'Disabled access',
            4: 'Dishwasher',
            8: 'Dryer',
            16: 'Washing machine',
            32: 'Microwave oven',
            128: 'Freezer',
            256: 'Oven',
            512: 'Fridge',
            1024: 'Bathtub',
            2048: 'Heating system',
            4096: 'Electric car plug'
        }
    };

    static FEATURES = [
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'wifi',
            featureName: 'feature-multimedia-wifi'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'tv',
            featureName: 'feature-multimedia-tv'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.ONE_THOUSAND_TWENTY_FOUR_BITS,
            icon: 'bathtub',
            featureName: 'feature-practical-bathtub'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'dishwasher',
            featureName: 'feature-practical-dishwasher'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'washing-machine',
            featureName: 'feature-practical-washing-machine'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'dryer',
            featureName: 'feature-practical-dryer'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.TWO_THOUSAND_FORTY_EIGHT_BITS,
            icon: 'heating-system',
            featureName: 'feature-practical-heating-system'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.FOUR_THOUSAND_NINETY_SIX_BITS,
            icon: 'electric-car-plug',
            featureName: 'feature-practical-electric-car-plug'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'disabled-access',
            featureName: 'feature-practical-disabled-access'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'microwave-oven',
            featureName: 'feature-practical-microwave-oven'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.ONE_HUNDRED_TWENTY_EIGHT_BITS,
            icon: 'freezer',
            featureName: 'feature-practical-freezer'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.TWO_HUNDRED_FIFTY_SIX_BITS,
            icon: 'oven',
            featureName: 'feature-practical-oven'
        },
        {
            category: Feature.AMENITIES_PRACTICAL,
            value: Feature.AMENITIES_BIT.FIVE_HUNDRED_TWELVE_BITS,
            icon: 'fridge',
            featureName: 'feature-practical-fridge'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'computer',
            featureName: 'feature-multimedia-computer'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'internet',
            featureName: 'feature-multimedia-internet'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'home-cinema',
            featureName: 'feature-multimedia-home-cinema'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.SIXTY_FOUR_BITS,
            icon: 'satellite-cable',
            featureName: 'feature-multimedia-satellite-cable'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.ONE_HUNDRED_TWENTY_EIGHT_BITS,
            icon: 'phone',
            featureName: 'feature-multimedia-phone'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.FIVE_HUNDRED_TWELVE_BITS,
            icon: 'video-game-console',
            featureName: 'feature-multimedia-video-game-console'
        },
        {
            category: Feature.AMENITIES_MULTIMEDIA,
            value: Feature.AMENITIES_BIT.ONE_THOUSAND_TWENTY_FOUR_BITS,
            icon: 'smart-tv',
            featureName: 'feature-multimedia-smart-tv'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'swimming-pool',
            featureName: 'feature-outdoor-swimming-pool'
        },
        {
            category: Feature.AMENITIES_KIDS,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'baby-bed',
            featureName: 'baby_bed',
            customLabel: 'home:baby_bed'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'garden',
            featureName: 'feature-outdoor-garden'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.SIXTY_FOUR_BITS,
            icon: 'balcony-terrace',
            featureName: 'feature-outdoor-balcony-terrace'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'elevator',
            featureName: 'feature-various-elevator'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'ac',
            featureName: 'feature-various-ac'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.SIXTY_FOUR_BITS,
            icon: 'fireplace',
            featureName: 'feature-various-fireplace'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'parking-space',
            featureName: 'feature-various-parking-space'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'car',
            featureName: 'feature-transportation-car'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'bicycle',
            featureName: 'feature-transportation-bicycle'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.ONE_HUNDRED_TWENTY_EIGHT_BITS,
            icon: 'gym',
            featureName: 'feature-various-gym'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'bbq',
            featureName: 'feature-outdoor-bbq'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'jacuzzi',
            featureName: 'feature-various-jacuzzi'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'motorcycle',
            featureName: 'feature-transportation-motorcycle'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'tennis-court',
            featureName: 'feature-outdoor-tennis-court'
        },
        {
            category: Feature.AMENITIES_OUTDOOR,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'ping-pong-table',
            featureName: 'feature-outdoor-ping-pong-table'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'sauna',
            featureName: 'feature-various-sauna'
        },
        {
            category: Feature.AMENITIES_VARIOUS,
            value: Feature.AMENITIES_BIT.TWO_HUNDRED_FIFTY_SIX_BITS,
            icon: 'pool-table',
            featureName: 'feature-various-pool-table'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'motor-scooter',
            featureName: 'feature-transportation-motor-scooter'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'motorboat',
            featureName: 'feature-transportation-motorboat'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'sailboat',
            featureName: 'feature-transportation-sailboat'
        },
        {
            category: Feature.OPTS_TRANSPORTATION,
            value: Feature.AMENITIES_BIT.SIXTY_FOUR_BITS,
            icon: 'electric-car',
            featureName: 'feature-transportation-electric-car'
        },
        {
            category: Feature.OPTS_PERSONAL,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'baby-sitter',
            featureName: 'feature-personal-baby-sitter'
        },
        {
            category: Feature.OPTS_PERSONAL,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'cook',
            featureName: 'feature-personal-cook'
        },
        {
            category: Feature.OPTS_PERSONAL,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'guide',
            featureName: 'feature-personal-guide'
        },
        {
            category: Feature.AMENITIES_KIDS,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'kids-toys',
            featureName: 'feature-kids-kids-toys'
        },
        {
            category: Feature.AMENITIES_KIDS,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'kids-playground',
            old_category: Feature.AMENITIES_OUTDOOR,
            old_value: Feature.AMENITIES_BIT.EIGHT_BITS,
            featureName: 'feature-kids-kids-playground'
        },
        {
            category: Feature.AMENITIES_KIDS,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'baby-gear',
            old_category: Feature.AMENITIES_PRACTICAL,
            old_value: Feature.AMENITIES_BIT.TWO_BIT,
            featureName: 'feature-kids-baby-gear'
        },
        {
            category: Feature.AMENITIES_KIDS,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'secured-swimming-pool',
            featureName: 'feature-kids-secured-swimming-pool'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'renewable-energy-provider',
            featureName: 'feature-eco-renewable-energy-provider'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'low-consumption-machines',
            featureName: 'feature-eco-low-consumption-machines'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'selective-waste-sorting',
            featureName: 'feature-eco-motor-selective-waste-sorting'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'vegetable-garden',
            featureName: 'feature-eco-vegetable-garden'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'public-transit-access',
            featureName: 'feature-eco-public-transit-access'
        },
        {
            category: Feature.AMENITIES_ECO,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'solar-panels',
            featureName: 'feature-eco-solar-panels'
        },
        {
            category: Feature.AMENITIES_REMOTE,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'high-speed-connection',
            featureName: 'feature-remote-high-speed-connection'
        },
        {
            category: Feature.AMENITIES_REMOTE,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'dedicated-work-space',
            featureName: 'feature-remote-dedicated-work-space'
        },
        {
            category: Feature.RULE,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'children-welcome',
            featureName: 'feature-rule-children-welcome'
        },
        {
            category: Feature.RULE,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'pets-welcome',
            featureName: 'feature-rule-pets-welcome'
        },
        {
            category: Feature.RULE,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'smokers-welcome',
            featureName: 'feature-rule-smokers-welcome'
        },
        { category: Feature.FAVOR, value: Feature.AMENITIES_BIT.ONE_BIT, icon: 'dog-to-feed' },
        { category: Feature.FAVOR, value: Feature.AMENITIES_BIT.TWO_BIT, icon: 'cat-to-feed' },
        { category: Feature.FAVOR, value: Feature.AMENITIES_BIT.FOUR_BITS, icon: 'plants-to-water' },
        // Need refacto with category 8 and some clean back side, impact with component HomeAllergiesToggle and InputRulesList
        {
            category: Feature.AMENITIES_ANIMALS,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'cat-to-feed',
            name: 'cat',
            label: 'feature-allergy.cat'
        },
        {
            category: Feature.AMENITIES_ANIMALS,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'dog-to-feed',
            name: 'dog',
            label: 'feature-allergy.dog'
        },
        {
            category: Feature.AMENITIES_ANIMALS,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'rabbit',
            name: 'other',
            label: 'feature-allergy.other'
        },

        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.ONE_BIT,
            icon: 'countryside',
            featureName: 'feature-surrounding-tags-countryside',
            description: 'home:description_countryside'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.TWO_BIT,
            icon: 'mountains',
            featureName: 'feature-surrounding-tags-mountains',
            description: 'home:description_mountains'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.FOUR_BITS,
            icon: 'seaside',
            featureName: 'feature-surrounding-tags-seaside',
            description: 'home:description_seaside'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.EIGHT_BITS,
            icon: 'lakes',
            featureName: 'feature-surrounding-tags-lakes',
            description: 'home:description_lakes'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.SIXTEEN_BITS,
            icon: 'cities',
            featureName: 'feature-surrounding-tags-cities',
            description: 'home:description_cities'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.THIRTY_TWO_BITS,
            icon: 'villages',
            featureName: 'feature-surrounding-tags-villages',
            description: 'home:description_villages'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.SIXTY_FOUR_BITS,
            icon: 'isolated',
            featureName: 'feature-surrounding-tags-isolated',
            description: 'home:description_isolated'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.ONE_HUNDRED_TWENTY_EIGHT_BITS,
            icon: 'island',
            featureName: 'feature-surrounding-tags-island',
            description: 'home:description_island'
        },
        {
            category: Feature.SURROUNDING_TAGS,
            value: Feature.AMENITIES_BIT.TWO_HUNDRED_FIFTY_SIX_BITS,
            icon: 'river',
            featureName: 'feature-surrounding-tags-river',
            description: 'home:description_river'
        }
    ];

    static FEATURES_GROUPS = [
        {
            title: 'home:amenity-exchangers-favorites-section.title',
            titleView: 'home:amenity-exchangers-favorites-section.title-view',
            type: Feature.AMENITIES_TYPE_EXCHANGERS_FAVS,
            features: [Feature.AMENITIES_MULTIMEDIA, Feature.AMENITIES_PRACTICAL],
            label: [
                Feature.AMENITIES_LABEL[Feature.AMENITIES_MULTIMEDIA],
                Feature.AMENITIES_LABEL[Feature.AMENITIES_PRACTICAL]
            ]
        },
        {
            title: 'home:amenity-unique-facilities-section.title',
            titleView: 'home:amenity-unique-facilities-section.title-view',
            type: Feature.AMENITIES_TYPE_UNIQUE_FACILITIES,
            features: [
                Feature.AMENITIES_VARIOUS,
                Feature.OPTS_TRANSPORTATION,
                Feature.OPTS_PERSONAL,
                Feature.AMENITIES_OUTDOOR
            ],
            label: [
                Feature.AMENITIES_LABEL[Feature.AMENITIES_VARIOUS],
                Feature.AMENITIES_LABEL[Feature.OPTS_TRANSPORTATION],
                Feature.AMENITIES_LABEL[Feature.OPTS_PERSONAL],
                Feature.AMENITIES_LABEL[Feature.AMENITIES_OUTDOOR]
            ]
        },
        {
            title: 'home:amenity.kids-friendly.title',
            type: Feature.AMENITIES_TYPE_KIDS,
            features: [Feature.AMENITIES_KIDS],
            label: [Feature.AMENITIES_LABEL[Feature.AMENITIES_KIDS]]
        },
        {
            title: 'home:amenity.remote-friendly.title',
            type: Feature.AMENITIES_TYPE_REMOTE,
            features: [Feature.AMENITIES_REMOTE],
            label: [Feature.AMENITIES_LABEL[Feature.AMENITIES_REMOTE]]
        },
        {
            title: 'home:amenity-eco-responsable-amenities-section.title',
            type: Feature.AMENITIES_TYPE_ECO,
            features: [Feature.AMENITIES_ECO],
            label: [Feature.AMENITIES_LABEL[Feature.AMENITIES_ECO]]
        },
        {
            title: 'home:rule-favor-section.title',
            type: Feature.AMENITIES_TYPE_RULE_FAVOR,
            features: [Feature.RULE, Feature.FAVOR]
        },
        {
            title: 'home:surrounding-tags-section.title',
            type: Feature.SURROUNDING_TYPE_TAGS,
            features: [Feature.SURROUNDING_TAGS],
            label: [Feature.AMENITIES_LABEL[Feature.SURROUNDING_TAGS]]
        }
    ];

    static getAmenitiesForLabel(label) {
        return Feature.AMENITIES_LABEL_DETAIL[label];
    }

    static filterAmenities() {
        const whiteListed = [
            'wifi',
            'washing-machine',
            'dishwasher',
            'dryer',
            'bathtub',
            'heating-system',
            'swimming-pool',
            'garden',
            'balcony-terrace',
            'car',
            'elevator',
            'jacuzzi',
            'parking-space',
            'ac',
            'tv',
            'fireplace',
            'cleaning-person',
            'doorman',
            'bicycle',
            'bbq',
            'gym',
            'piano',
            'kids-toys',
            'kids-playground',
            'baby-gear',
            'secured-swimming-pool',
            'baby-bed',
            'high-speed-connection',
            'dedicated-work-space',
            'electric-car-plug'
        ];
        return Feature.FEATURES.filter((feature) => whiteListed.includes(feature.icon));
    }

    static bitmaskValues(bitmask) {
        const bits = [];
        for (let i = 1; i <= Feature.AMENITIES_BIT.FOUR_THOUSAND_NINETY_SIX_BITS; i *= 2) {
            if (i & bitmask) {
                bits.push(i);
            }
        }
        return bits;
    }

    static getFeatureLabel(feature) {
        return (
            feature?.customLabel ??
            `home:feature-${Feature.AMENITIES_LABEL[feature?.category] ?? ''}-${feature?.icon}`
        );
    }
}
