// Navigation
export const NAVIGATION_ACTION = {
    CLICK: 'click',
    VIEW: 'view'
};

export const NAVIGATION_AREAS = {
    VIDEO: 'video',
    HOW_IT_WORKS: 'how_it_works',
    HEADER: 'header',
    SIGN_IN_MODAL: 'sign_in_modal',
    SIGN_UP_MODAL: 'sign_up_modal',
    SIGN_UP_EMAIL_MODAL: 'sign_up_email_modal',
    CANCEL_MEMBERSHIP_MODAL: 'cancel_membership_modal',
    FILTERS: 'filters',
    USER_PROFILE: 'user_profile',
    MAP: 'map',
    CAROUSEL: 'carousel',
    DETAILS: 'details',
    HOMECARD_RESULTS_PHOTO: 'homecard_results_photo',
    HOMECARD_RESULTS_FAVORITES: 'homecard_results_favorites',
    HOMECARD_MAP_PHOTO: 'homecard_map_photo',
    HOMECARD_MAP_FAVORITES: 'homecard_map_favorites',
    HEADER_AVATAR_MENU: 'header_avatar_menu'
};

export const NAVIGATION_TEXT = {
    SIGN_UP: 'sign_up',
    SIGN_UP_FACEBOOK: 'sign_up_facebook',
    SIGN_UP_APPLE: 'sign_up_apple',
    SIGN_UP_EMAIL: 'sign_up_email',
    SIGN_IN: 'sign_in',
    SIGN_IN_FACEBOOK: 'sign_in_facebook',
    SIGN_IN_APPLE: 'sign_in_apple',
    SIGN_IN_EMAIL: 'sign_in_email',
    CAROUSEL_SIGN_UP: 'carousel_sign_up',
    ACTIVATE_AUTO_RENEWAL: 'activate_auto_renewal',
    CANCEL_MY_MEMBERSHIP: 'cancel_my_membership',
    KEEP_IT_GOING: 'keep_it_going',
    ADD_TO_FAVORITES_SIGN_UP: 'add_to_favorites_sign_up',
    CONTACT_SIGN_UP: 'contact_sign_up',
    PHOTO_SIGN_UP: 'photo_sign_up',
    VERIFY_YOUR_ACCOUNT: 'verify_your_account',
    VERIFY_YOUR_PHONE: 'verify_your_phone',
    VERIFY_YOUR_NEW_HOME: 'verify_your_new_home',
    VERIFICATION_PENDING: 'verification_pending',
    VERIFICATION_DONE: 'verification_done'
};
