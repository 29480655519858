import { browserHistory, useRouterHistory } from 'react-router';
import * as Sentry from '@sentry/react';

const basename = document.location.pathname.match(/^(\/\w{2})\/.+$/);

const history = useRouterHistory(() => browserHistory)({
    basename: basename ? basename[1] : '/'
});

// update Analytics tracking pageview when history change
history.listen((location) => {
    try {
        window.analytics.track('page-viewed', {
            url: location.pathname
        });
    } catch (err) {
        Sentry.captureException(err);
    }
});

export const redirect = (path) => {
    document.location.assign(history.createHref(path));
};

export default history;
